<template>
<b-card title="Інформація">
  <b-row v-if="data">
    <b-col>
      <ul class="list-unstyled">
        <li><strong>{{ $t('name') }}</strong>: {{ data.documentName }}</li>
        <li><strong>{{ $t('cash_registers') }}</strong>: {{ data.cashRegisters }}</li>
        <li><strong>{{ $t('date') }}</strong>: {{ data.date }}</li>
        <li><strong>{{ $t('operation_type') }}</strong>: {{ data.operationType }}</li>
        <li><strong>{{ $t('user') }}</strong>: {{ data.user }}</li>
        <li><strong>{{ $t('sum') }}</strong>: {{ data.sum | currency }}</li>
        <li>
          <strong>{{ $t('payment_status') }}</strong>:
          <span v-if="data.paymentStatus">{{ $t('paid') }}</span>
          <span v-else>{{ $t('unpaid') }}</span>
        </li>
      </ul>
      <h4>{{ data.operationType }}</h4>
      <component v-if="data.type" :is="data.type"/>
    </b-col>
  </b-row>
</b-card>
</template>
<script>
import axios from 'axios'
import ParcheseTable from '@/pages/operation/show/parchase_table'
export default {
  name: 'Show',
  components: {
    parchese_table: ParcheseTable
  },
  data: () => ({
    data: null
  }),
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1 + 'operations/show/' + this.$route.params.id
      axios.get(apiUrl).then(resp => {
        this.data = resp.data
      })
    }
  }
}
</script>
